import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import ForgotPassword from './pages/forgotPassword/ForgotPassword'
import Login from './pages/login/Login'
import RedefinePassword from './pages/redefinePassword/RedefinePassword'
import Register from './pages/register/Register'
import Management from './pages/management/Management'
import History from './pages/history'
import AddProduct from './pages/addProduct/AddProduct'
import NotFound from './pages/notFound'
import Products from './pages/products/Products'
import Settings from './pages/settings'
import Home from './pages/home'
import Doubts from './pages/support/doubts'
import ContactUs from './pages/support/contactUs'
import Access from './pages/financial/access'
import Balance from './pages/financial/balance'
import DefaultLayout from './layout/defaultLayout'

import CheckEmail from './pages/checkEmail'

import { useAuth } from './context/AuthContext'
import ModalContext from './context/ModalContext'

export default function Routes() {
  const { authenticated, user } = useAuth()

  function AuthRoute({ ...rest }) {
    if (user && !user?.storeData?.active) {
      return <Redirect to={{
        pathname: '/login',
        state: { accountStatus: 'pending' }
      }} />
    }

    if (!authenticated) {
      return <Redirect to='/login' />
    }

    return <Route {...rest} />
  }

  function CustomRoute({ ...rest }) {
    if (authenticated && user?.storeData?.active) {
      return <Redirect to='/' />
    }

    return <Route {...rest} />
  }

  function MainRouter({ info, ...rest }) {
    return (
      <DefaultLayout info={info} >
        <AuthRoute {...rest} />
      </DefaultLayout>
    )
  }

  return (
    <BrowserRouter>
      <ModalContext>
        <Switch>
          <CustomRoute path="/login" exact component={Login} />
          <CustomRoute path="/register" component={Register} />
          <Route path="/forgotpassword" component={ForgotPassword} />
          <Route path="/redefinepassword/:jwt" component={RedefinePassword} />
          <Route path="/activeemail/:id" component={CheckEmail} />

          <MainRouter exact path="/" component={Home} info={{ title: 'Início' }} />
          <MainRouter path="/management" component={Management} info={{ title: 'Pedidos', subtitle: 'Gerenciamento' }} />
          <MainRouter path="/history" component={History} info={{ title: 'Pedidos', subtitle: 'Histórico' }} />
          <MainRouter path="/addProduct" component={AddProduct} info={{ title: 'Vitrini', subtitle: 'Adicionar produto' }} />
          <MainRouter path="/products" component={Products} info={{ title: 'Vitrini', subtitle: 'Seus produtos' }} />
          <MainRouter path="/accessfinancial" component={Access} info={{ title: 'Financeiro' }} />
          <MainRouter path="/financial" component={Balance} info={{ title: 'Financeiro', subtitle: 'Balanço' }} />
          <MainRouter path="/config/:page?" component={Settings} info={{ title: 'Configurações' }} />
          <MainRouter path="/doubt" component={Doubts} info={{ title: 'Suporte', subtitle: 'Perguntas frequentes' }} />
          <MainRouter path="/contact-us" component={ContactUs} info={{ title: 'Suporte', subtitle: 'Fale conosco' }} />
          <MainRouter component={NotFound} info={{ title: 'Início' }} />
        </Switch>
      </ModalContext>
    </BrowserRouter>
  )
}
